import { Breakpoints, styled } from '@vw-marketing/us-components';

interface StylesProps {
  isTablet: boolean;
}

export const StyledSideContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFilterContainer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

export const StyledInputZipWrapper = styled.div<StylesProps>`
  ${({ isTablet }: StylesProps) =>
    `
    width: ${isTablet ? '100%' : '300px'};
    & .input-text-field {
      font-size: 20px;
      height: 56px;
      margin: 0;
    }
  `}
`;

export const StyledChipsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 12px;
  align-items: center;
`;

export const StyledOffersTabContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledFilterIconSection = styled.div`
  @media (min-width: ${Breakpoints.b1280}px) {
    display: none;
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 22px;
  border: solid 1px #001e50;
  padding: 7px 12px;
  margin: 3px 0 2px;

  svg {
    margin-right: 5px;
  }
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
