import { Breakpoints, styled } from '@vw-marketing/us-components';

interface StyledTagContainerProps {
  background: string;
}

export const StyledTagContainer: any = styled.div<StyledTagContainerProps>`
  align-items: center;
  background: ${({ background }) => background || '#a7a7a7'};
  display: flex;
  height: auto;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const StyledModelCardWrapper: any = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 12px;

  @media (max-width: ${Breakpoints.b560}px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

export const StyledHeadOfferWrapper = styled.div`
  width: 100%;
  margin: 0 0 35px 0;
  max-width: 320px;

  @media (min-width: ${Breakpoints.b1920}px) {
    max-width: 400px;
  }

  @media (max-width: ${Breakpoints.b1600}px) and (min-width: ${Breakpoints.b960}px) {
    max-width: 310px;
  }

  @media (max-width: ${Breakpoints.b960}px) and (min-width: ${Breakpoints.b560}px) {
    min-width: 320px;
  }

  @media (max-width: ${Breakpoints.b560}px) {
    min-width: 100vw;
  }
`;

export const StyledImageGroup: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  position: relative;
`;

export const StyledButtonWrapper: any = styled.div`
  width: 100%;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backgound-color: red;
  gap: 15px;

  @media (max-width: ${Breakpoints.b560}px) {
    flex-direction: row;
  }
`;

export const StyledButtonSection: any = styled.div`
  width: 160px;
`;

interface StyledBackgroundProps {
  readonly backgroundColor: string;
}

export const StyledBackground: any = styled.div<StyledBackgroundProps>`
  background: ${props => props.backgroundColor || '#ccab0d'};
  height: 260px;
  left: 0px;
  padding-top: 46%;
  position: absolute;
  right: 0px;
  top: 0px;

  @media (max-width: ${Breakpoints.b560}px) {
    height: 200px;
    padding-top: 43%;
  }
`;

interface StyledTextWrapperProps {
  readonly headTextColor: string;
  readonly backgroundSection: string;
  readonly applyBg: boolean;
}

export const StyledTextWrapper: any = styled.div<StyledTextWrapperProps>`
  padding: 20px 20px 32px 20px;
  color: ${props => props.headTextColor || '#000'};
  ${(props: StyledTextWrapperProps) =>
    props.applyBg &&
    props.backgroundSection &&
    `background: ${props.backgroundSection};
    padding-bottom: 50px;`}
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyleLinkTextWrapper: any = styled.div`
  padding: 10px 0 0 0;
  width: 100%;
`;

export const StyledImagePlaceholder: any = styled.div``;

export const StyledImageWrapper: any = styled.div`
  position: relative;
  pointer-events: none;
  margin-top: 35px;

  > img:nth-child(2) {
    transform: scale(1.1);
    margin-top: -32px;
    padding-bottom: 6px;
  }

  @media (max-width: ${Breakpoints.b560}px) {
    > img:nth-child(2) {
      transform: scale(0.9);
      margin-top: -100px;
    }
  }
`;

export const StyledOffersTextSection: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 15px 0;
  position: relative;
  width: 100%;
  font-weight: 300;

  @media (max-width: ${Breakpoints.b560}px) {
    margin: 25px 0 -15px 0;

    &::before,
    &::after {
      content: "";
      flex: 1;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      background-color: #DFE4E8;
      margin: 0 20px;
    }

    &::before {
      margin-right: 15px; /* Space between left line and text */
    }

    &::after {
      margin-left: 15px; /* Space between right line and text */
    }

    > * {
      display: inline-block;
    }
  }ß
`;

export const StyledNoResultsWrapper: any = styled.div`
  width: 100%;

  @media (max-width: ${Breakpoints.b2560}px) {
    width: calc(100% - 850px);
  }

  @media (max-width: ${Breakpoints.b1920}px) {
    width: calc(100% - 780px);
  }

  @media (max-width: ${Breakpoints.b1600}px) {
    width: calc(100% - 600px);
  }

  @media (max-width: ${Breakpoints.b1280}px) {
    width: calc(100% - 500px);
  }

  @media (max-width: ${Breakpoints.b960}px) {
    width: calc(100% - 350px);
  }

  @media (max-width: ${Breakpoints.b560}px) {
    width: 100%;
  }
`;

export const StyledTrimLabel = styled.div`
  margin-bottom: 8px;
`;
