import React from 'react';
import { StyledButtonSection, StyledButtonWrapper } from '../newStyles';
import {
  DisableFocusWrapper,
  ButtonCustomLink,
  ButtonAppearance,
  buttonPaddingGenerator,
  TextColor,
  TextAppearance,
  ButtonCustom,
  Text,
} from '@vw-marketing/us-components';
export interface OfferButton {
  url?: string;
  openNewTab?: boolean;
  onClick?: () => void;
  ctaLabel?: string;
  type: 'link' | 'button';
  appearance?: ButtonAppearance;
}
interface OfferButtonGroup {
  firstButtonConfig?: OfferButton;
  secondButtonConfig?: OfferButton;
}

const OfferButton: React.FC<OfferButton> = ({
  type,
  openNewTab,
  onClick,
  ctaLabel,
  url,
  appearance,
}) => {
  const buttonTextStyle = {
    color: TextColor.inherit,
    appearance: TextAppearance.label150,
  };
  switch (type) {
    case 'link': {
      return (
        <DisableFocusWrapper>
          {/* @ts-ignore */}
          <ButtonCustomLink
            appearance={appearance}
            href={url}
            padding={buttonPaddingGenerator(0)}
            target={openNewTab ? '_blank' : '_self'}
            onClick={onClick}
            stretchContent={true}
          >
            <Text {...buttonTextStyle}>{ctaLabel}</Text>
          </ButtonCustomLink>
        </DisableFocusWrapper>
      );
    }
    case 'button': {
      return (
        <DisableFocusWrapper>
          {/* @ts-ignore */}
          <ButtonCustom
            appearance={appearance}
            onClick={onClick}
            padding={buttonPaddingGenerator(0)}
          >
            <Text {...buttonTextStyle}>{ctaLabel}</Text>
          </ButtonCustom>
        </DisableFocusWrapper>
      );
    }
  }
};
const OfferButtonGroup: React.FC<OfferButtonGroup> = ({
  firstButtonConfig,
  secondButtonConfig,
}) => {
  const isOneButton = !firstButtonConfig || !secondButtonConfig;
  return (
    <StyledButtonWrapper oneElement={isOneButton}>
      {firstButtonConfig && (
        <StyledButtonSection>
          <OfferButton
            {...firstButtonConfig}
            appearance={ButtonAppearance.Secondary}
          />
        </StyledButtonSection>
      )}
      {secondButtonConfig && (
        <StyledButtonSection>
          <OfferButton
            {...secondButtonConfig}
            appearance={ButtonAppearance.Primary}
          />
        </StyledButtonSection>
      )}
    </StyledButtonWrapper>
  );
};

export default OfferButtonGroup;
