import { Breakpoints, styled } from '@vw-marketing/us-components';

interface StyledOffersSectionWrapperProps {
  readonly specialOffersRedesignEnable: boolean;
}

export const StyledOffersSectionWrapper: any = styled.div<StyledOffersSectionWrapperProps>`
  display: flex;
  flex-direction: ${(props: StyledOffersSectionWrapperProps) => props.specialOffersRedesignEnable ? 'column' : 'row'};
  flex-wrap: wrap;
  padding: 0 20px 10px 20px;
  width: 100%;

  @media (max-width: ${Breakpoints.b560}px) {
    padding: 0;
  }

  @media (min-width: ${Breakpoints.b960}px) {
    padding: 0;
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    flex-wrap: nowrap;
  }
`;

export const StyledLeftContainer: any = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.b1280}px) {
    width: 40%;
  }
`;

export const StyledRightContainer: any = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.b1280}px) {
    width: 60%;
  }
`;

export const StyledBottomContainer: any = styled.div`
  margin-top: 30px;

  @media(max-width: ${Breakpoints.b560}px) {
    margin-top: 0;
  }
`;

export const OverflowWrapper: any = styled.div`
  overflow-x: hidden;
  width: 100%;
`;

interface LayoutWrapperProps {
  readonly isTier2: boolean;
}

export const LayoutWrapper: any = styled.div<LayoutWrapperProps>`
  display: flex;
  padding: 0;
  overflow-x: visible;
  padding-top: ${(props: LayoutWrapperProps) =>
    props.isTier2 ? '15px;' : '30px;'};

  @media (min-width: ${Breakpoints.b960}px) {
    padding: 0 ${(props: any) => props.theme.size.grid002};
    padding-top: 30px;
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    padding-top: ${(props: LayoutWrapperProps) =>
    props.isTier2 ? '50px;' : '30px;'};
  }
`;

export const StyledOffersContainer: any = styled.div`
  width: 100%;
`;

export const StyledOffersTitle: any = styled.div`
  align-items: center;
  display: flex;
  justify-content: start;
  padding: 20px 0;
  width: 100%;

  h1 {
    font-weight: normal;
  }

  @media(max-width: ${Breakpoints.b560}px) {
    padding: 20px;
  }
`;
