import { Breakpoints, styled } from '@vw-marketing/us-components';
import { ElementBounding } from '../../../../../services/use-element-bounding';

interface DropdownContentProps {
  active?: boolean;
  isSticky?: boolean;
  isMobile?: boolean;
  modalActive?: boolean;
  elementBounding?: ElementBounding;
}

export const StyledFilterDropdownContainer = styled.div<DropdownContentProps>`
  ${({ active, isSticky, modalActive }) => `
    width: 100%;
    z-index: ${isSticky || modalActive ? '10001' : '1'};

    @media (min-width: ${Breakpoints.b960}px) {
      width: 300px;
      z-index:${active ? '10001' : '0'};
    }

    @media (max-width: ${Breakpoints.b960}px) {
      position: absolute;
      top: 100px;
      width: 100vw;
      z-index: 1001;
      ${!isSticky ?
      `
        right: 0px;
      `
        :
      `
        top: 0px;
        right: 0px;
        left: 0px;
      `
      }
    }
  `}
`;

export const StyledFilterDropdownButton = styled.button<DropdownContentProps>`
  ${({ active }) => `
  background: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #001E50;
  justify-content: center;
  border: solid 2px #dfe4e8;
  width: 100%;
  height: 50px;
  & > .filter-dropdown-icon {
    display: none;
  }

  &:focus {
    border: solid 2px #2661fa;
    color: #2661fa;
  }

  ${
    active
      ? `border: solid 2px #2661fa;
         color: #2661fa;`
      : ''
  }

  @media (min-width: ${Breakpoints.b560}px) {
    border: none;
    justify-content: space-between;
    border-bottom: solid 1px #001E50;
    height: 56px;

    & > .filter-dropdown-icon {
      display: block;
    }

    &:focus {
      border: none;
      border-bottom: solid 1px #2661fa;
    }

    ${
      active
        ? `border: none;
           border-bottom: solid 1px #2661fa;`
        : ''
    }
  }
  `}
`;

export const StyledFilterDropdownContent = styled.div<DropdownContentProps>`
  ${({ isSticky, elementBounding, theme, isMobile }) => `
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  max-height: calc(100vh - ${isSticky ? '80' : '135'}px);
  position: ${isMobile ? 'relative' : 'absolute'};
  z-index: 1002;

  ::-webkit-scrollbar {
      width: 5px;
      border-radius: 2.5px;
      margin-left: -5px;
  }
  ::-webkit-scrollbar-thumb {
      background: #d8d8d8;
      border-radius: 2.5px;
      height: 119px;
  }



  @media (min-width: ${Breakpoints.b560}px) {
    max-height: calc(100vh - ${(elementBounding && elementBounding?.top) || 0}px);
    padding: 0;
    ${!isSticky ?
      `
        left: 70px ;
        margin-left: calc(-1 * ${theme.size.grid002});
        width: auto;
      `
        :
      `
        left: 0;
      `
    }
  }

  @media (min-width: ${Breakpoints.b960}px) {
    ${!isSticky ?
      `
        left: 100px;
        width: 100%;
      `
        :
      `
        left: 0;
      `
    }
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    margin-left: 0;
    ${!isSticky ?
      `
        left: auto;
        width: auto;
      `
        :
      `
        width: auto;
        left: 0;
      `
    }
  }

  @media (min-width: ${Breakpoints.b1920}px) {
    ${isSticky ? `width: 88vw;` : ''}
  }
  `}
`;

export const StyledFilterCloseButtonWrapper = styled.div`
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;

  @media (min-width: ${Breakpoints.b560}px) {
    display: none;
  }
`;

export const StyledIcon = styled.span`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2px;
  pointer-events: none;
  .animation svg {
    transition: transform 0.5s ease;
    transform: rotate(0deg);
  }
  .animation.active svg {
    transform: rotate(180deg);
  }
`;

export const StyledFilterLabelContainer = styled.div<DropdownContentProps>`
  ${({ isSticky }) => `
    display: flex;
    align-items: center;
    gap: 10px;
    ${isSticky ? `font-size: 20px; line-height: 32px;` : ``}
  `}
`;

export const StyledFiltersContainer = styled.div<DropdownContentProps>`
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: auto;
  overflow: hidden scroll;
  padding: 20px 32px 0;

  @media (max-width: ${Breakpoints.b960}px) {
    flex-direction: column;
    padding: ${({ isSticky }) => isSticky ? '20px 32px 0' : ''};
  }

  @media (min-width: ${Breakpoints.b560}px) {
    height: auto;
    justify-content: space-between;
    padding: 35px 20px;
  }

  @media (min-width: ${Breakpoints.b960}px) {
    padding: 35px 58px;
  }

  ${({ isSticky }) => `${isSticky && `padding-left: 62px;`}`}
`;

export const StyledButtonsGroup = styled.div<DropdownContentProps>`
  align-items: center;
  border-top: ${(props: DropdownContentProps) => props.isMobile ? '1px solid #DFE4E8' : 'none'};
  display: flex;
  gap: ${(props: DropdownContentProps) => (props.isMobile ? '8px' : '32px')};
  justify-content: ${(props: DropdownContentProps) => props.isMobile ? 'center' : 'flex-end'};
  padding: ${(props: DropdownContentProps) => props.isMobile ? '15px' : '20px 32px'};

  button {
    width: ${(props: DropdownContentProps) => props.isMobile ? '100%' : 'max-content'};

    @media (max-width: ${Breakpoints.b560}px) {
      padding: 5px;
    }

    @media (min-width: ${Breakpoints.b560}px) and (max-width: ${Breakpoints.b960}px) {
      width: max-content;
    }
  }
`;

export const StyledHeaderTitleContainer = styled.div`
  display: flex;
  flex-grow: 1;

  > div {
    flex-grow: 1;
  }
`;

export const StyledFilterMobileHeader = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #dfe4e8;
  background-color: #fff;
  z-index: 10;
  > button {
    margin-left: auto;
  }
`;
