import React, { memo } from 'react';

import {
  Breakpoints,
  ButtonAppearance,
  ButtonCustom,
  DisableFocusWrapper,
  LinkButton,
  Offers,
  Text,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
  buttonPaddingGenerator,
} from '@vw-marketing/us-components';

import { AppConstants } from '../../../../utils/app-constants';
import { ImageMapModel } from '../../../../config/offer-images-map';
import {
  ModelTag,
  OffersModel,
} from '../../../../hooks-store/typings/incentive-store';
import { useStore } from '../../../../hooks-store/store';
import IncentiveConstants from '../../../../hooks-store/incentive-store/actions/incentive-constants';
import {
  clearText,
  getDateFormat,
  transformIrisUrl,
} from '../../../../utils/general';
import {
  StyledBlackContainer,
  StyledButtonSection,
  StyledButtonWrapper,
  StyledInformationOfferContainer,
  StyledInformationWrapper,
  StyledModelNameWrapper,
  StyledOfferCardWrapper,
  StyledOfferMicroText,
  StyledOfferSubtitle,
  StyledOfferTitle,
  StyledOfferTypeLabel,
  StyledOfferTypeLabelWrapper,
  StyledOpacityWrapper,
  StyledSectionButton,
  StyledShowOfferDetails,
} from './styled';
import { onHandleFormButtonTracking } from '../../../../utils/tagging-helpers';
import { useFeatureAppConfig } from '../../../../services/use-feature-app-config';
import { useTrackingManager } from '../../../../context/use-tracking-manager';
import { useTranslations } from '../../../../services/use-translations';

interface NewOfferCardProps {
  readonly offer: OffersModel;
  readonly imageMap?: ImageMapModel;
  readonly opacity?: boolean;
  readonly index: number;
  readonly modelTag?: ModelTag;
}

export const NewOfferCard = memo(
  ({
    offer,
    imageMap,
    opacity,
    index,
    modelTag,
  }: NewOfferCardProps): JSX.Element | null => {
    const isSalesEventOffer = offer?.modelTag?.isModelTagEnabled && index === 0;
    const { faHostServerUrl = '' } = useFeatureAppConfig();
    const modelImage =
      offer?.modelKey !== 'all'
        ? imageMap?.modelImage
        : faHostServerUrl + AppConstants.Ngw6Logo480x216;
    const [
      store,
      { [IncentiveConstants.SetModalFormData]: setModalFormData },
    ] = useStore();
    const translations = useTranslations().tier1simplifiedVersion.localOffers
      .offerCard;
    const trackingManager = useTrackingManager();

    const setContactDealerFormData = () => {
      setModalFormData(
        {
          ...offer,
          mediaImageUrl:
            transformIrisUrl(
              modelImage,
              AppConstants.IrisImgPositionE13,
              '960',
              '432',
              imageMap?.modelYear,
              imageMap?.modelKey,
            ) || faHostServerUrl + AppConstants.Ngw6Logo960x432,
        },
        modelTag,
        store.dealer,
        AppConstants.ContactDealerFormType,
        AppConstants.ContactDealerFormName,
      );
      onHandleFormButtonTracking(
        offer,
        translations.contactDealer,
        trackingManager,
        store,
      );
    };

    const setGetAQuoteFormData = () => {
      setModalFormData(
        {
          ...offer,
          mediaImageUrl:
            transformIrisUrl(
              modelImage,
              AppConstants.IrisImgPositionE13,
              '960',
              '432',
              imageMap?.modelYear,
              imageMap?.modelKey,
            ) || faHostServerUrl + AppConstants.Ngw6Logo960x432,
        },
        modelTag,
        store.dealer,
        AppConstants.GetAQuoteFormType,
        AppConstants.GetAQuoteFormName,
      );
      onHandleFormButtonTracking(
        offer,
        translations.getAQuote,
        trackingManager,
        store,
      );
    };

    const setDetailsFormData = () => {
      setModalFormData(
        {
          ...offer,
          mediaImageUrl:
            transformIrisUrl(
              modelImage,
              AppConstants.IrisImgPositionE13,
              '960',
              '432',
              imageMap?.modelYear,
              imageMap?.modelKey,
            ) || faHostServerUrl + AppConstants.Ngw6Logo960x432,
        },
        modelTag,
        store.dealer,
        AppConstants.OfferDetailsFormType,
        AppConstants.OfferDetailsFormName,
      );
      onHandleFormButtonTracking(
        offer,
        translations.offerDetails,
        trackingManager,
        store,
      );
    };

    return (
      <StyledOfferCardWrapper>
        <StyledInformationWrapper>
          <StyledOpacityWrapper isOpacity={opacity} />
          {isSalesEventOffer && (
            <StyledOfferTypeLabelWrapper>
              <StyledBlackContainer>
                <StyledOfferTypeLabel
                  backgroundColor={offer.modelTag.background}
                >
                  <TextCustom
                    appearance={TextAppearance.label100}
                    tag={TextTag.span}
                    style={{
                      [Breakpoints.default]: {
                        fontSize: TextSize.TextSize14,
                        lineHeight: TextLineHeight.TextLineHeight24,
                        color: TextColor.inherit,
                        fontWeight: TextWeight.regular,
                      },
                    }}
                  >
                    <Offers variant="small" /> {offer.modelTag.label}
                  </TextCustom>
                </StyledOfferTypeLabel>
              </StyledBlackContainer>
            </StyledOfferTypeLabelWrapper>
          )}
          <StyledInformationOfferContainer>
            <StyledModelNameWrapper>
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.p}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight18,
                    fontWeight: TextWeight.regular,
                  },
                }}
              >
                {offer.modelKey &&
                offer.modelKey === AppConstants.AllOfferModelKey
                  ? offer.modelDisplayName
                  : `${offer.dealCarYear[0] || ''} ${offer.modelDisplayName ||
                      ''} ${offer.dealCarTrimName[0] || ''}`}
              </TextCustom>
              <TextCustom
                appearance={TextAppearance.label100}
                style={{
                  [Breakpoints.default]: {
                    color: TextColor.secondary,
                    fontSize: TextSize.TextSize14,
                    fontWeight: TextWeight.regular,
                    lineHeight: TextLineHeight.TextLineHeight24,
                  },
                }}
              >
                {offer.dealCarTrimName[0]}
              </TextCustom>
            </StyledModelNameWrapper>
            <StyledOfferTitle>
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.h2}
                style={{
                  [Breakpoints.default]: {
                    fontWeight: TextWeight.bold,
                    fontSize: TextSize.TextSize24,
                    lineHeight: TextLineHeight.TextLineHeight29,
                  },
                }}
              >
                {clearText(offer.dealTitle)}
              </TextCustom>
            </StyledOfferTitle>
            <StyledOfferSubtitle>
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.p}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight18,
                    fontWeight: TextWeight.regular,
                  },
                }}
              >
                {offer.dealSubtitle}
              </TextCustom>
            </StyledOfferSubtitle>
            <StyledOfferMicroText>
              {offer.dealAdditionalTerms && (
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.regular,
                    },
                  }}
                >
                  {offer.dealAdditionalTerms}
                </TextCustom>
              )}
              {offer.dealExclusions && (
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.regular,
                    },
                  }}
                >
                  {offer.dealExclusions}
                </TextCustom>
              )}
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.p}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight18,
                    color: TextColor.inherit,
                    fontWeight: TextWeight.regular,
                  },
                }}
              >
                {offer.dealLocaleType === 'Daa'
                  ? `Local Offer. Offer ends ${getDateFormat(
                      offer.dealEndDate,
                    )}`
                  : `${offer.dealLocaleType} Offer. Offer ends ${getDateFormat(
                      offer.dealEndDate,
                    )}`}
              </TextCustom>
            </StyledOfferMicroText>
            {store.dealer && (
              <StyledShowOfferDetails>
                <DisableFocusWrapper>
                  {/* @ts-ignore */}
                  <LinkButton onClick={() => setDetailsFormData()} isInline>
                    <TextCustom
                      appearance={TextAppearance.label100}
                      tag={TextTag.p}
                      style={{
                        [Breakpoints.default]: {
                          fontSize: TextSize.TextSize14,
                          lineHeight: TextLineHeight.TextLineHeight16,
                          color: TextColor.inherit,
                          fontWeight: TextWeight.bold,
                        },
                      }}
                    >
                      {translations.offerDetails}
                    </TextCustom>
                  </LinkButton>
                </DisableFocusWrapper>
              </StyledShowOfferDetails>
            )}
          </StyledInformationOfferContainer>
        </StyledInformationWrapper>
        {store.dealer && (
          <StyledSectionButton>
            <StyledButtonWrapper>
              <StyledButtonSection>
                <DisableFocusWrapper>
                  {/* @ts-ignore */}
                  <ButtonCustom
                    appearance={ButtonAppearance.Secondary}
                    onClick={() => setGetAQuoteFormData()}
                    padding={buttonPaddingGenerator(0)}
                    stretchContent
                    className={'get-a-quote'}
                  >
                    <Text
                      color={TextColor.inherit}
                      appearance={TextAppearance.label150}
                    >
                      {translations.getAQuote}
                    </Text>
                  </ButtonCustom>
                </DisableFocusWrapper>
              </StyledButtonSection>
              <StyledButtonSection>
                <DisableFocusWrapper>
                  {/* @ts-ignore */}
                  <ButtonCustom
                    appearance={ButtonAppearance.Primary}
                    onClick={() => setContactDealerFormData()}
                    padding={buttonPaddingGenerator(0, 22, 22, 22)}
                    stretchContent
                    className={'contact-dealer'}
                  >
                    <Text
                      color={TextColor.inherit}
                      appearance={TextAppearance.label150}
                    >
                      {translations.contactDealer}
                    </Text>
                  </ButtonCustom>
                </DisableFocusWrapper>
              </StyledButtonSection>
            </StyledButtonWrapper>
          </StyledSectionButton>
        )}
      </StyledOfferCardWrapper>
    );
  },
);
