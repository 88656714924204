import React from 'react';

import {
  Breakpoints,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';

import { BottomContainerSection } from './bottom-container-section/BottomContainerSection';
import { FilterContainerSection } from './filter-container-section';
import { LayoutWrapper, OverflowWrapper, StyledBottomContainer, StyledLeftContainer, StyledOffersContainer, StyledOffersSectionWrapper, StyledOffersTitle, StyledRightContainer } from './styled';
import { LeftContainerSection } from './left-container-section';
import { RightContainerSection } from './right-container-section';
import { StickyHeader } from '../../components/sticky-header';
import { useFeatureAppConfig } from '../../services/use-feature-app-config';
import { useStore } from '../../hooks-store/store';
import { useTranslations } from '../../services/use-translations';

export const OffersSection = () => {
  const { specialOffersRedesignEnable } = useFeatureAppConfig();
  const offersTitle = useTranslations().offersTitle;
  const store = useStore()[0];

  return (
    <OverflowWrapper>
      <LayoutWrapper isTier2={store.tier2 ? true : false}>
        <StyledOffersSectionWrapper
          specialOffersRedesignEnable={specialOffersRedesignEnable}
        >
          {specialOffersRedesignEnable ? (
            <StyledOffersContainer>
              <StyledOffersTitle>
                <TextCustom
                  appearance={TextAppearance.headline100}
                  tag={TextTag.h1}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize32,
                      lineHeight: TextLineHeight.TextLineHeight36,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.light,
                    },
                  }}
                >
                  {offersTitle}
                </TextCustom>
              </StyledOffersTitle>
              <StickyHeader noPadding>
                <FilterContainerSection />
              </StickyHeader>
              <StyledBottomContainer>
                <BottomContainerSection />
              </StyledBottomContainer>
            </StyledOffersContainer>
          ) : (
            <>
              <StyledLeftContainer>
                <LeftContainerSection />
              </StyledLeftContainer>
              <StyledRightContainer>
                <RightContainerSection />
              </StyledRightContainer>
            </>
          )}
        </StyledOffersSectionWrapper>
      </LayoutWrapper>
    </OverflowWrapper>
  );
};
